<template>
	<div
		class="menu-slide"
		data-anima="bottom"
	>
		<TextPlay
			weight="semibold"
			color="var(--fontcolor)"
			:text="$t('settings.title')"
		/>
		<div class="menu-slide__items">
			<div
				class="menu-slide__items__item"
				:class="{ 'menu-slide__items__item--active': type === 'geral' }"
				@click="changeType('geral')"
			>
				<IconSax
					name="setting-2"
					size="16px"
					primary
				/>
				<TextPlay
					size="sm"
					weight="semibold"
					color="var(--fontcolor)"
					:text="$t('settings.general.title')"
				/>
			</div>
			<div
				class="menu-slide__items__item"
				:class="{ 'menu-slide__items__item--active': type === 'custom' }"
				@click="changeType('custom')"
			>
				<IconSax
					name="designtools"
					size="16px"
					primary
				/>
				<TextPlay
					size="sm"
					weight="semibold"
					color="var(--fontcolor)"
					:text="$t('settings.appearance.title')"
				/>
			</div>
			<div
				class="menu-slide__items__item"
				:class="{ 'menu-slide__items__item--active': type === 'custom_camp' }"
				@click="changeType('custom_camp')"
			>
				<IconSax
					name="firstline"
					size="16px"
					primary
				/>
				<TextPlay
					size="sm"
					weight="semibold"
					color="var(--fontcolor)"
					:text="$t('settings.custom_fields')"
				/>
			</div>
			<div
				class="menu-slide__items__item"
				:class="{ 'menu-slide__items__item--active': type === 'plans' }"
				@click="changeType('plans')"
			>
				<IconSax
					name="dollar-circle"
					size="16px"
					primary
				/>
				<TextPlay
					size="sm"
					weight="semibold"
					color="var(--fontcolor)"
					:text="$t('settings.hosting_plans.title')"
				/>
			</div>
			<div
				v-if="feature_access_free"
				class="menu-slide__items__item"
				:class="{ 'menu-slide__items__item--active': type === 'free' }"
				@click="changeType('free')"
			>
				<IconSax
					name="user-tick"
					size="16px"
					primary
				/>
				<TextPlay
					size="sm"
					weight="semibold"
					color="var(--fontcolor)"
					:text="$t('settings.free_access.title')"
				/>
			</div>
			<div
				class="menu-slide__items__item"
				:class="{ 'menu-slide__items__item--active': type === 'webhooks' }"
				@click="changeType('webhooks')"
			>
				<IconSax
					name="data"
					size="16px"
					primary
				/>
				<TextPlay
					size="sm"
					weight="semibold"
					color="var(--fontcolor)"
					:text="$t('settings.webhooks.title')"
				/>
			</div>
			<div
				class="menu-slide__items__item"
				:class="{ 'menu-slide__items__item--active': type === 'advanced' }"
				@click="changeType('advanced')"
			>
				<IconSax
					name="shield-tick"
					size="16px"
					primary
				/>
				<TextPlay
					size="sm"
					weight="semibold"
					color="var(--fontcolor)"
					:text="$t('settings.security.title')"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props:['feature_access_free'],
  data(){
    return{
      type: 'geral'
    }
  },
  methods: {
    changeType(type) {
      this.type = type;
      this.$emit("changeType", type);
    },
  },
  mounted(){
    if(this.$route.query.page){
      let type = this.$route.query.page;
      this.type = type;
      this.$emit("changeType", this.type);
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-slide {
	position: sticky;
	top: 32px;
	display: flex;
	flex-direction: column;
	background-color: var(--caption-bg-box);
	padding: 24px 20px;
	width: 100%;
	border-radius: 16px;
	gap: 20px;

	&__items {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;

		&__item {
			display: flex;
			align-items: center;
			padding: 8px;
			cursor: pointer;
			gap: 8px;
			width: 100%;

			&--active,
			&:hover {
				[data-theme="dark"] & {
					background-color: var(--neutral-gray-700);
				}
				background-color: var(--neutral-gray-200);
				border-radius: 8px;
			}
		}
	}
}
</style>
